
import { defineComponent } from "vue";
import UIcon from "./index.vue";
export default defineComponent({
  name: "IconBox",
  components: { UIcon },
  props: {
    name: {
      type: String,
      default: "",
    },
  },
});
