import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-spinner-mask"
}
const _hoisted_2 = { class: "v-spinner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_loading = _resolveComponent("van-loading")!

  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_van_loading, {
            color: "var(--van-danger-color)",
            size: "26",
            vertical: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}