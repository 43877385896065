export default {
  getTodoAndApplyNum: {
    url: "/v1/getTodoAndApplyNum",
  },
  getTaskList: {
    url: "/v1/task_list",
    /* reqInterceptors: [
      (res: any) => {
        console.log(res);
        return res;
      },
    ],
    resInterceptors: [
      (res: any) => {
        console.log(res);
        return res;
      },
    ], */
  },
  getClassAndNum: "/v1/task_class_and_num",
  getApprovedList: "/v1/approved_list",
  approve: "/v1/approve_instance_batch",
  // 代理审批
  proxyApprove: "/v1/approve_instance_batch_proxy",
  getApproveDetail: "/v1/instance_detail",
  getApproveTrace: "/v1/instance_trace",
  getAvatar: "v1/avatar",

  // 获取可申请的流程定义列表
  getProcessList: "/v1/process_definition/mobile_can_apply_list",
  // 获取可申请的流程定义分类下的流程定义数量
  getApplyNum: "/v1/process_definition/mobile_can_apply_list_num",
  // 获取用户收藏流程
  getMarkProcessList: {
    url: "/v1/process_definition/mark_process_list",
    mockData: [
      { processDefinitionName: "员工费用报销", jmeFormUrl: "" },
      { processDefinitionName: "离职流程", jmeFormUrl: "" },
      { processDefinitionName: "安居计划", jmeFormUrl: "" },
      { processDefinitionName: "工作居住证申请", jmeFormUrl: "" },
      { processDefinitionName: "京东廉洁报备", jmeFormUrl: "" },
      { processDefinitionName: "国际物流部通融理赔申请", jmeFormUrl: "" },
    ],
  },
  mark: "/v1/process_definition/mark",
  mark_del: "/v1/process_definition/mark_del",
  // 获取审批详情页跳转类型
  viewType: "/v1/view_type",
  // 获取审批页按钮
  getButtons: "/v1/buttons",
  // 加签
  addSign: "/v1/forward_to",
  // 转办
  reassign: "/v1/reassign",
  // 审批事件校验
  beforeApprove: "/v1/before_approve",
  // 申请列表
  getApplyList: "/v1/apply_list",
  // 取消申请
  onCancel: "/v1/cancel_process_with_sys_code_and_comment",
  // 催办
  onUrge: "/v1/process_reminder",
  previewOffice: "/v1/preview_office_doc",
  getDecodeOffer: "/v1/custom/getOfferManagerDecryptData",
  getSubFromDetail: "/v1/instance_subform_detail",
  getRobotAppId: {
    url: "/v1/getRobotAppId",
  },
  //是否可建群
  isCanCreateChat: "/v1/isCanCreateChat",
  //群聊是否已存在
  getJmeGroup: "/v1/queryJmeGroup",
  //创建群聊
  createJmeGroup: "/v1/createJmeGroup",
};
