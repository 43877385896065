import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "process-comps-card" }
const _hoisted_2 = {
  key: 0,
  class: "process-comps-card-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", null, _toDisplayString(_ctx.title), 1)
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "title"),
    _createElementVNode("div", {
      class: "process-comps-card-body",
      style: _normalizeStyle(_ctx.bodyStyle)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 4)
  ]))
}