(window._iconfont_svg_string_2881684 = `
  <svg>
  <symbol id="icon-task-card" viewBox="0 0 1024 1024">
  <path d="M0 0h1024v1024H0z" fill="#ffffff" >
  </path>
  <path d="M245.9648 115.2A140.8 140.8 0 0 0 105.1648 256v102.4a140.8 140.8 0 0 0 140.8 140.8h532.0704A140.8 140.8 0 0 0 918.8352 358.4V256a140.8 140.8 0 0 0-140.8-140.8H245.9648zM181.9648 256c0-35.328 28.672-64 64-64h532.0704c35.328 0 64 28.672 64 64v102.4c0 35.328-28.672 64-64 64H245.9648c-35.328 0-64-28.672-64-64V256z m64 268.8A140.8 140.8 0 0 0 105.1648 665.6v102.4a140.8 140.8 0 0 0 140.8 140.8h532.0704a140.8 140.8 0 0 0 140.8-140.8v-102.4a140.8 140.8 0 0 0-140.8-140.8H245.9648zM181.9648 665.6c0-35.328 28.672-64 64-64h532.0704c35.328 0 64 28.672 64 64v102.4c0 35.328-28.672 64-64 64H245.9648c-35.328 0-64-28.672-64-64v-102.4z" fill="" >
  </path>
  </symbol>
  <symbol id="icon-task-list" viewBox="0 0 1024 1024">
  <path d="M24.380952 24.380952h975.238096v975.238096H24.380952z" fill="#ffffff" >
  </path>
  <path d="M259.803429 134.095238a134.095238 134.095238 0 0 0-134.095239 134.095238v97.52381a134.095238 134.095238 0 0 0 134.095239 134.095238h97.523809a134.095238 134.095238 0 0 0 134.095238-134.095238v-97.52381a134.095238 134.095238 0 0 0-134.095238-134.095238h-97.523809zM198.851048 268.190476c0-33.645714 27.306667-60.952381 60.952381-60.952381h97.523809c33.645714 0 60.952381 27.306667 60.952381 60.952381v97.52381c0 33.645714-27.306667 60.952381-60.952381 60.952381h-97.523809c-33.645714 0-60.952381-27.306667-60.952381-60.952381v-97.52381z m60.952381 256a134.095238 134.095238 0 0 0-134.095239 134.095238v97.52381a134.095238 134.095238 0 0 0 134.095239 134.095238h97.523809a134.095238 134.095238 0 0 0 134.095238-134.095238v-97.52381a134.095238 134.095238 0 0 0-134.095238-134.095238h-97.523809z m-60.952381 134.095238c0-33.645714 27.306667-60.952381 60.952381-60.952381h97.523809c33.645714 0 60.952381 27.306667 60.952381 60.952381v97.52381c0 33.645714-27.306667 60.952381-60.952381 60.952381h-97.523809c-33.645714 0-60.952381-27.306667-60.952381-60.952381v-97.52381z m333.726476-449.536a36.571429 36.571429 0 0 1 36.571428-36.571428h292.571429a36.571429 36.571429 0 0 1 0 73.142857h-292.571429a36.571429 36.571429 0 0 1-36.571428-36.571429z m36.571428 366.787048a36.571429 36.571429 0 0 0 0 73.142857h292.571429a36.571429 36.571429 0 0 0 0-73.142857h-292.571429z m-36.571428-165.156572a36.571429 36.571429 0 0 1 36.571428-36.571428h292.571429a36.571429 36.571429 0 0 1 0 73.142857h-292.571429a36.571429 36.571429 0 0 1-36.571428-36.571429z m36.571428 366.884572a36.571429 36.571429 0 1 0 0 73.142857h292.571429a36.571429 36.571429 0 0 0 0-73.142857h-292.571429z" fill="" >
  </path>
  </symbol>
  <symbol id="icon-copy" viewBox="0 0 1024 1024">
  <path d="M746.932 698.108"  >
  </path>
  <path d="M925.731 288.698c-1.261-1.18-3.607-3.272-6.902-6.343-5.486-5.112-11.615-10.758-18.236-16.891-18.921-17.526-38.003-35.028-56.046-51.397-2.038-1.848-2.038-1.835-4.077-3.682-24.075-21.795-44.156-39.556-58.996-52.076-8.682-7.325-15.517-12.807-20.539-16.426-3.333-2.402-6.043-4.13-8.715-5.396-3.365-1.595-6.48-2.566-10.905-2.483C729.478 134.227 720 143.77 720 155.734l0 42.475 0 42.475 0 84.95L720 347l21.205 0L890 347l0 595L358.689 942C323.429 942 295 913.132 295 877.922L295 177l361.205 0c11.736 0 21.25-9.771 21.25-21.5s-9.514-21.5-21.25-21.5l-382.5 0L252 134l0 21.734L252 813l-52.421 0C166.646 813 140 786.928 140 754.678L140 72l566.286 0C739.29 72 766 98.154 766 130.404L766 134l40 0 0-3.596C806 76.596 761.271 33 706.286 33L119.958 33 100 33l0 19.506 0 702.172C100 808.463 144.642 852 199.579 852L252 852l0 25.922C252 936.612 299.979 984 358.689 984l552.515 0L932 984l0-21.237L932 325.635 932 304l0.433 0C932.432 299 930.196 292.878 925.731 288.698zM762 304l0-63.315L762 198.21l0-0.273c14 11.479 30.3 26.369 49.711 43.942 2.022 1.832 2.136 1.832 4.157 3.665 17.923 16.259 36.957 33.492 55.779 50.926 2.878 2.666 5.713 5.531 8.391 7.531L762 304.001z"  >
  </path>
  <path d="M816.936 436 407.295 436c-10.996 0-19.91 8.727-19.91 19.5 0 10.77 8.914 19.5 19.91 19.5l409.641 0c11 0 19.914-8.73 19.914-19.5C836.85 444.727 827.936 436 816.936 436z"  >
  </path>
  <path d="M816.936 553 407.295 553c-10.996 0-19.91 8.727-19.91 19.5 0 10.774 8.914 19.5 19.91 19.5l409.641 0c11 0 19.914-8.726 19.914-19.5C836.85 561.727 827.936 553 816.936 553z"  >
  </path>
  <path d="M816.936 689 407.295 689c-10.996 0-19.91 8.729-19.91 19.503 0 10.769 8.914 19.497 19.91 19.497l409.641 0c11 0 19.914-8.729 19.914-19.497C836.85 697.729 827.936 689 816.936 689z"  >
  </path>
  </symbol>
  <symbol id="icon-shangchuanfujian-tianjiafujian-10" viewBox="0 0 1024 1024">
  <path d="M631.466667 650.24H175.786667c-37.546667 0-68.266667-30.72-68.266667-68.266667V126.293333c0-37.546667 30.72-68.266667 68.266667-68.266666H631.466667c37.546667 0 68.266667 30.72 68.266666 68.266666V580.266667c0 39.253333-30.72 69.973333-68.266666 69.973333z" fill="" opacity=".3" >
  </path>
  <path d="M621.226667 901.12c-34.133333 0-66.56-13.653333-92.16-37.546667-51.2-51.2-51.2-133.12 0-182.613333l170.666666-172.373333c10.24-10.24 25.6-10.24 35.84 0s10.24 25.6 0 35.84L564.906667 716.8c-30.72 30.72-30.72 80.213333 0 110.933333 15.36 15.36 34.133333 22.186667 54.613333 22.186667 20.48 0 40.96-8.533333 54.613333-22.186667l204.8-204.8c8.533333-8.533333 13.653333-18.773333 13.653334-30.72s-5.12-22.186667-13.653334-30.72c-17.066667-17.066667-44.373333-17.066667-61.44 0l-204.8 204.8c-3.413333 3.413333-3.413333 8.533333 0 13.653334 3.413333 3.413333 8.533333 3.413333 13.653334 0l172.373333-172.373334c10.24-10.24 25.6-10.24 35.84 0s10.24 25.6 0 35.84l-170.666667 170.666667c-22.186667 22.186667-63.146667 22.186667-85.333333 0-23.893333-23.893333-23.893333-61.44 0-85.333333l204.8-204.8c37.546667-37.546667 97.28-37.546667 134.826667 0 18.773333 17.066667 27.306667 40.96 27.306666 66.56s-10.24 49.493333-27.306666 66.56l-204.8 204.8c-25.6 25.6-58.026667 39.253333-92.16 39.253333z" fill="" >
  </path>
  <path d="M527.36 989.866667h-358.4c-47.786667 0-85.333333-40.96-85.333333-92.16V126.293333c0-51.2 37.546667-92.16 85.333333-92.16H785.066667c47.786667 0 85.333333 40.96 85.333333 92.16v285.013334c0 13.653333-11.946667 25.6-25.6 25.6s-25.6-11.946667-25.6-25.6V126.293333c0-22.186667-15.36-40.96-34.133333-40.96H168.96c-18.773333 0-34.133333 18.773333-34.133333 40.96v769.706667c0 22.186667 15.36 40.96 34.133333 40.96H529.066667c13.653333 0 25.6 11.946667 25.6 25.6s-11.946667 27.306667-27.306667 27.306667z" fill="" >
  </path>
  <path d="M607.573333 310.613333H244.053333c-13.653333 0-25.6-11.946667-25.6-25.6s11.946667-25.6 25.6-25.6h363.52c13.653333 0 25.6 11.946667 25.6 25.6s-10.24 25.6-25.6 25.6zM459.093333 513.706667H244.053333c-13.653333 0-25.6-11.946667-25.6-25.6s11.946667-25.6 25.6-25.6h213.333334c13.653333 0 25.6 11.946667 25.6 25.6s-10.24 25.6-23.893334 25.6z" fill="" >
  </path>
  </symbol>
  <symbol id="icon-tianjiarenyuan" viewBox="0 0 1024 1024">
  <path d="M925.8176 691.9936h-110.8928V578.816a38.9888 38.9888 0 1 0-77.9712 0v113.1776H626.08a39.7824 39.7824 0 0 0 0 79.4432h110.8736v113.0176a38.9824 38.9824 0 0 0 77.9712 0V771.264h110.8928a39.7888 39.7888 0 0 0 0-79.2064v-0.064z m-337.1712 190.912H142.7904c18.2144-160.4032 153.2096-282.0096 314.6432-283.456a38.272 38.272 0 0 0 20.4544-6.1312c135.552-14.0736 238.2784-128.7936 237.3696-265.0816 1.3504-145.7088-115.648-264.9344-261.3696-266.368-145.7472 1.3888-262.784 120.672-261.408 266.432a267.584 267.584 0 0 0 116.416 221.4528c-149.9328 63.0144-247.2576 210.0032-246.7456 372.6528a39.3216 39.3216 0 0 0 38.7264 39.7248h487.584a39.808 39.808 0 0 0 37.6384-39.7248 39.808 39.808 0 0 0-37.6384-39.7376l0.0576 0.2368h0.128zM453.888 141.2608c102.2592 1.1072 184.32 84.7808 183.4368 187.04a182.912 182.912 0 0 1-90.7072 161.3312 183.936 183.936 0 0 1-185.44 0A182.8992 182.8992 0 0 1 270.4 328.3008c-0.8768-102.2784 81.216-185.9648 183.488-187.04z m0 0"  >
  </path>
  </symbol>
  <symbol id="icon-user" viewBox="0 0 1024 1024">
  <path d="M512 0C229.376 0 0 229.376 0 512s229.376 512 512 512 512-229.376 512-512S794.624 0 512 0z m-3.072 179.2c84.992 0 154.112 69.12 154.112 154.112S593.92 487.424 508.928 487.424 354.816 418.304 354.816 333.312 423.424 179.2 508.928 179.2zM239.104 791.552c-1.536-158.208 75.776-256.512 172.544-294.912l99.328 165.888 104.96-162.304c93.696 40.96 167.936 137.728 169.472 291.328-172.032 68.096-353.28 73.216-546.304 0z"  >
  </path>
  </symbol>
  <symbol id="icon-edit" viewBox="0 0 1024 1024">
  <path d="M862.016 828H161.024q-14.016 0-24 10.016t-10.016 24.512 10.016 24.512 24 10.016h700.992q14.016 0 24-10.016t10.016-24.512-10.016-24.512-24-10.016z m-528-103.008q7.008 0 14.016-3.008h0.992l196.992-92L878.016 297.984q18.016-18.016 18.016-43.008t-18.016-42.016l-67.008-67.008q-18.016-18.016-42.496-18.016t-42.496 18.016L395.008 475.968l-2.016 4-91.008 194.016q-8.992 18.016 2.016 35.008 11.008 16 30.016 16zM728 240L768 200l55.008 55.008-40 40zM402.016 620.992l48.992-103.008 228.992-228.992 55.008 55.008-228.992 228.992z"  >
  </path>
  </symbol>
  <symbol id="icon-search" viewBox="0 0 1024 1024">
  <path d="M938.670589 832.937476 724.072023 618.264209c-2.493797-2.481518-5.266957-4.377704-7.883551-6.445806 36.231179-55.625902 57.400315-121.893164 57.400315-193.285145 0-196.1064-158.901033-355.056552-355.045295-355.056552-196.033745 0-355.056552 158.951175-355.056552 355.056552 0 196.130959 159.022807 355.044272 355.056552 355.044272 71.342862 0 137.697105-21.216207 193.404871-57.471946 2.033309 2.700505 3.894703 5.376451 6.351662 7.836479l214.636429 214.69578c14.635325 14.610766 33.736359 21.886473 52.850695 21.886473 19.122523 0 38.223556-7.275707 52.859905-21.851681C967.797977 909.451104 967.797977 862.134449 938.670589 832.937476M418.543492 661.46615c-133.911896 0-242.933915-108.996436-242.933915-242.933915 0-133.912919 109.020996-242.946194 242.933915-242.946194 133.912919 0 242.921635 109.033275 242.921635 242.946194C661.465127 552.469714 552.456411 661.46615 418.543492 661.46615"  >
  </path>
  </symbol>
  <symbol id="icon-dingdanzhongxin" viewBox="0 0 1024 1024">
  <path d="M423.654394 109.60121l209.662137 0c24.267706 0 44.74918-23.535019 44.74918-52.608172 0-29.001521-20.482497-52.473095-46.075385-52.473095L423.654394 4.519943c-25.560142 0-46.012963 23.471574-46.012963 52.473095C377.641431 86.066191 398.129045 109.60121 423.654394 109.60121z"  >
  </path>
  <path d="M858.331331 77.248362l-60.067051 0c0 0-42.030255 11.601222-42.030255 17.435091 0 44.190455-31.026644 87.680968-68.023256 87.680968L369.421212 182.364421c-38.847773 0-69.942979-43.490513-69.942979-87.680968 0.033769-5.833869-37.724183-17.435091-37.724183-17.435091l-65.596997 0c-84.430948 0-105.183598 49.392944-105.183598 120.661104l0 705.733353c0 76.503395 29.701462 119.333876 111.547539 119.333876l649.445372 0c81.808214 0 121.586173-33.549094 121.586173-119.333876l0-705.733353C973.552539 126.641305 936.296007 77.248362 858.331331 77.248362zM751.726362 774.026762 300.409442 774.026762c-16.610306 0-33.216519-21.011546-33.216519-38.250162 0-17.10661 14.055111-31.822776 30.694069-31.822776l449.990714 0c16.576537 0 30.631648 13.389962 31.956829 31.822776C779.835559 753.015216 768.297782 774.026762 751.726362 774.026762zM753.515101 587.205842 302.264697 587.205842c-16.643052 0-27.217898-18.49626-27.217898-35.670407 0-17.203824 14.055111-34.406624 30.665417-34.406624l449.989691 0c16.639982 0 30.695093 15.944134 31.953759 34.406624C787.655665 568.709583 770.153036 587.205842 753.515101 587.205842zM753.515101 395.276578 302.264697 395.276578c-16.643052 0-27.217898-18.432815-27.217898-35.670407 0-17.169031 14.055111-34.372855 30.665417-34.372855l449.989691 0c16.639982 0 30.695093 15.945157 31.953759 34.372855C787.655665 376.843764 770.153036 395.276578 753.515101 395.276578z"  >
  </path>
  </symbol>
  <symbol id="icon-shenpi" viewBox="0 0 1024 1024">
  <path d="M422.399 601.6V493.323c-79.111-34.622-134.4-113.655-134.4-205.323 0-123.711 100.54-224 224-224 123.711 0 224 100.545 224 224 0 91.814-55.379 170.725-134.4 205.313V601.6h201.472c61.925 0 112.128 49.716 112.128 112.003V825.6H108.801V713.598c0-61.855 50.444-111.998 112.126-111.998h201.472zM153.601 870.4h716.793V960H153.6v-89.6z"  >
  </path>
  </symbol>
  </svg>`),
  (function (a) {
    var t = (t = document.getElementsByTagName("script"))[t.length - 1],
      c = t.getAttribute("data-injectcss"),
      // eslint-disable-next-line no-redeclare
      t = t.getAttribute("data-disable-injectsvg");
    if (!t) {
      var e,
        l,
        i,
        n,
        o,
        h = function (t, c) {
          c.parentNode.insertBefore(t, c);
        };
      if (c && !a.__iconfont__svg__cssinject__) {
        a.__iconfont__svg__cssinject__ = !0;
        try {
          document.write(
            "<style>.svgfont {display: inline-block;width: 1em;height: 1em;fill: currentColor;vertical-align: -0.1em;font-size:16px;}</style>"
          );
        } catch (t) {
          console && console.log(t);
        }
      }
      (e = function () {
        var t,
          c = document.createElement("div");
        (c.innerHTML = a._iconfont_svg_string_2881684),
          (c = c.getElementsByTagName("svg")[0]) &&
            (c.setAttribute("aria-hidden", "true"),
            (c.style.position = "absolute"),
            (c.style.width = 0),
            (c.style.height = 0),
            (c.style.overflow = "hidden"),
            // eslint-disable-next-line no-self-assign
            (c = c),
            (t = document.body).firstChild
              ? h(c, t.firstChild)
              : t.appendChild(c));
      }),
        document.addEventListener
          ? ~["complete", "loaded", "interactive"].indexOf(document.readyState)
            ? setTimeout(e, 0)
            : ((l = function () {
                document.removeEventListener("DOMContentLoaded", l, !1), e();
              }),
              document.addEventListener("DOMContentLoaded", l, !1))
          : document.attachEvent &&
            ((i = e),
            (n = a.document),
            (o = !1),
            d(),
            (n.onreadystatechange = function () {
              "complete" == n.readyState &&
                ((n.onreadystatechange = null), s());
            }));
    }
    function s() {
      o || ((o = !0), i());
    }
    function d() {
      try {
        n.documentElement.doScroll("left");
      } catch (t) {
        return void setTimeout(d, 50);
      }
      s();
    }
  })(window);
