import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "process-comps-ellipsis-wrapper" }
const _hoisted_3 = { style: {"max-height":"90vh","overflow":"auto"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_overlay = _resolveComponent("van-overlay")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "el",
    class: "process-comps-ellipsis",
    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.show = true))
  }, [
    _createElementVNode("span", {
      class: "html-span",
      innerHTML: _ctx.titleHtml,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
    }, null, 8, _hoisted_1),
    (_ctx.isEllipsis)
      ? (_openBlock(), _createBlock(_component_van_overlay, {
          key: 0,
          show: _ctx.show,
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.show = false), ["stop"])),
          "lock-scroll": false,
          "custom-style": { zIndex: 1000, background: '#fff' }
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1)
            ])
          ]),
          _: 1
        }, 8, ["show"]))
      : _createCommentVNode("", true)
  ], 512))
}