
import { defineComponent, ref, nextTick, toRefs, watch } from "vue";
import DOMPurify from "dompurify";
export default defineComponent({
  name: "EllipsisText",
  props: {
    row: {
      type: String,
      default: "3",
    },
    title: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { row, title } = toRefs(props);
    const el = ref();
    const isEllipsis = ref(false);
    const show = ref(false);
    const titleHtml = ref(title);
    titleHtml.value = DOMPurify.sanitize(titleHtml.value);

    watch(
      title,
      () => {
        nextTick(() => {
          const height = el.value.offsetHeight;
          el.value.style.lineClamp = row.value;
          el.value.style["-webkit-line-clamp"] = row.value;
          isEllipsis.value = height - el.value.offsetHeight > 0;
        });
      },
      { immediate: true }
    );

    const handleClick = (e: any) => {
      e.stopPropagation();
      console.log("handleClick ===>", e);
      if (e.target?.className === "html-span") {
        show.value = true;
        return;
      }
      if (e.target?.tagName === "A") {
        e.preventDefault();
        try {
          const href = e.target.href;
          const url = new URL(href);
          if (href && ["http:", "https:"].includes(url.protocol)) {
            console.log("handleClick ===>", href);
            window.jme?.browser?.openUrl({ url: href });
          }
        } catch (e) {
          console.error(e);
        }
      }
    };
    return { el, isEllipsis, titleHtml, show, handleClick };
  },
});
