import { ref, inject, provide, readonly } from "vue";
import type { Ref } from "vue";

type Context = {
  loading: Ref<boolean>;
  updateLoading: (num: boolean) => void;
};

//provide的key,唯一令牌
const ContextSymbol = Symbol();

//提供者
export const contextProvide = () => {
  //全局loading
  const loading = ref<boolean>(false);
  //更新方法
  const updateLoading = (value: boolean) => {
    loading.value = value;
  };
  //提供的共享状态对象
  const depends = {
    loading: readonly(loading),
    updateLoading,
  };
  //使用provide api实现状态对象提供
  provide(ContextSymbol, depends);
  //返回状态对象，让同级可调用
  return depends;
};

//注入方法
export const contextInject = () => {
  //使用inject api注入状态
  const context = inject<Context>(ContextSymbol);
  //未共享就注入的错误校验
  if (!context) {
    throw new Error("Inject must be used after Provide");
  }
  //返回注入的贡献状态
  return context;
};
