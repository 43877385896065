import vRouter from "@/router";
import { TaskItemProps } from "@/views/type";
import { Toast } from "vant";
import fetchApi from "@/api";
import { isUpgrade, store, userAgent } from "@/utils";
import { ApplyItemProps } from "@/views/type";

const appInfo = window.jme.applet.getAppInfo();
const appId = process.env.VUE_APP_APPID;
const isDev = process.env.NODE_ENV === "development";

function putOriginForJoyGo(deepLink: string) {
  const [originStr, url] = deepLink.split("?url=");
  const _url = window.decodeURIComponent(url);
  const aSymbol = _url.includes("?") ? "&" : "?";
  return (
    originStr +
    "?url=" +
    window.encodeURIComponent(_url + aSymbol + "linkFromOrigin=oa")
  );
}

export const jumpDeepLink = (deepLink: string) => {
  let _deepLink = deepLink;
  if (deepLink && deepLink.substring(0, 7) === "jdme://") {
    if (
      deepLink.includes("joygo.jd.com") &&
      !deepLink.includes("linkFromOrigin")
    ) {
      _deepLink = putOriginForJoyGo(deepLink);
    }
    console.log(_deepLink);
    setTimeout(() => {
      window.open(_deepLink);
    }, 200);
  } else {
    Toast.fail("链接格式错误");
  }
};

export const taskListClick = (item: TaskItemProps) => {
  store.set("taskItem", item);
  let deepLink: string;
  if (isDev) {
    vRouter.push({
      path: "/approveDetail",
      query: { processInstanceId: item.reqId },
    });
    return;
  }
  if (
    appInfo &&
    appInfo.appVersion &&
    isUpgrade(appInfo.appVersion.replace("版本", ""), "6.23.0")
  ) {
    if (item.approveUrl.indexOf(`web/${appId}`) > -1) {
      vRouter.push({
        path: "/approveDetail",
        query: { processInstanceId: item.reqId },
      });
    } else {
      deepLink = item.approveUrl;
      jumpDeepLink(deepLink);
    }
  } else {
    if (item.jmeFormUrl) {
      deepLink = item.jmeFormUrl;
    } else if (item.taskType === "proxy" && item.assignee) {
      if (userAgent() === "ios") {
        deepLink = `jdme://appcenter/flowcenter/approve/${item.reqId}%24%40%23${item.assignee}?mparam=%7B%22flowCenterType%22%3A%22myApprove%22%7D`;
      } else {
        deepLink = `jdme://appcenter/flowcenter/approve/123/?mparam=%7B%22flowCenterType%22%3A%22myApprove%22%7D&bean=${item.reqId}%24%40%23${item.assignee}`;
      }
    } else {
      deepLink = `jdme://appcenter/flowcenter/approve/${item.reqId}?mparam=%7B%22flowCenterType%22%3A%22myApprove%22%7D`;
    }
    jumpDeepLink(deepLink);
  }
};

export const getAsyncTask = (reqId: string) => {
  return new Promise((resolve, reject) => {
    fetchApi
      .getTaskList()
      .data({
        conditionSearch: reqId,
        pageSize: 1,
        pageNo: 1,
      })
      .send("POST")
      .then((res) => {
        const TaskData = JSON.parse(res.data);
        const [node] = TaskData.result;
        if (node) {
          resolve(node);
        } else {
          reject();
        }
      });
  });
};

export const applyListClick = (item: ApplyItemProps) => {
  let deepLink: string;
  if (item.meViewUrl) {
    deepLink = item.meViewUrl;
    jumpDeepLink(deepLink);
  } else {
    vRouter.push({
      path: "/applyDetail",
      query: { processInstanceId: item.reqId },
    });
  }
};
