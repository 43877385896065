const CACHE = new WeakMap();

export default function cache(api: Record<string, Function>) {
  return Object.keys(api).reduce((result, name) => {
    const old = api[name];
    result[name] = function () {
      let apiMap: Map<string, Promise<any>> = CACHE.get(api);
      if (!apiMap) {
        apiMap = new Map();
        CACHE.set(api, apiMap);
      }

      const call = apiMap.get(name);
      if (call) {
        console.log("cann");
        (<any>call).cancel?.(new Error("操作取消了"));
      }

      const oldpromise = old.apply(api);
      const promise = oldpromise.then((res: any) => {
        apiMap.delete(name);
        return res;
      });
      const cancelablePromise = withCancel(promise);
      apiMap.set(name, cancelablePromise);
      return cancelablePromise;
    };

    return result;
  }, {} as Record<string, Function>);
}

export function withCancel(promise: Promise<any>) {
  let cancel;
  const res = new Promise((res, rej) => {
    cancel = rej;
    return promise.then(res, rej);
  });
  (res as any).cancel = cancel;
  return res;
}
