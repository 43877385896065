import { App } from "vue";
import {
  Popup,
  Search,
  Tabs,
  Tab,
  List,
  Cell,
  CellGroup,
  PullRefresh,
  SubmitBar,
  Checkbox,
  CheckboxGroup,
  Button,
  Loading,
  Collapse,
  CollapseItem,
  Overlay,
  Skeleton,
  Tag,
  Icon,
  Field,
  Dialog,
  DropdownMenu,
  DropdownItem,
  ActionSheet,
  DatetimePicker,
  SwipeCell,
} from "vant";
export function setVant(app: App<Element>) {
  app
    .use(Popup)
    .use(Search)
    .use(Tabs)
    .use(Tab)
    .use(List)
    .use(Cell)
    .use(CellGroup)
    .use(PullRefresh)
    .use(SubmitBar)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(Button)
    .use(Loading)
    .use(Collapse)
    .use(CollapseItem)
    .use(Overlay)
    .use(Skeleton)
    .use(Tag)
    .use(Icon)
    .use(Field)
    .use(Dialog)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(ActionSheet)
    .use(DatetimePicker)
    .use(SwipeCell);
}
