import axios from "axios";
import type { AxiosRequestConfig } from "axios";

import FetchRequestManager from "./FetchRequestManager";
import cache from "./cache";
export type ApiConfigMap = Record<string, AxiosRequestConfig | string>;
export type ApiMap = Record<string, () => FetchRequestManager>;

function request(config: AxiosRequestConfig): FetchRequestManager {
  const manager = new FetchRequestManager(config);

  const { reqInterceptors = [], resInterceptors = [] } = config as any;
  reqInterceptors.forEach((req: any) => manager.interceptRequest(req));
  resInterceptors.forEach((req: any) => manager.interceptResponse(req));

  const baseURL = process.env.VUE_APP_BASEURL;
  if (baseURL) {
    manager.config({
      baseURL,
    });
  }

  return manager;
}

export default function fetchApi(configMap: ApiConfigMap): ApiMap {
  return Object.keys(configMap).reduce((apiMap, key) => {
    let config = configMap[key];
    if (typeof config === "string") {
      config = { url: config };
    }
    apiMap[key] = () => request(config as AxiosRequestConfig);
    return apiMap;
  }, {} as ApiMap);
}

fetchApi.request = request;
fetchApi.defaults = axios.defaults;
export { FetchRequestManager, cache };

/* TODO:
  1. 考虑如何设置接口的cancel操作
  2. TSlint
*/
