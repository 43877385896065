import { App } from "@vue/runtime-dom";

import UIcon from "./icon/index.vue";
import IconBox from "./icon/IconBox.vue";
import UCard from "./common/Card.vue";
import Empty from "./common/Empty.vue";
import BaseLoading from "./common/BaseLoading.vue";
import EllipsisText from "./common/EllipsisText.vue";

const components: { [_: string]: any } = {
  UIcon,
  IconBox,
  UCard,
  Empty,
  BaseLoading,
  EllipsisText,
};

const install = function (app: App<Element>) {
  Object.keys(components).forEach((key) => {
    app.component(key, components[key]);
  });
};

export default { install };
