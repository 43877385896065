import { App } from "@vue/runtime-dom";
import type { Directive } from "vue";

import RippleDirective from "@/directives/ripple";
import focusDirective from "@/directives/focus";

const directives: { [_: string]: Directive<any, any> } = {
  ripple: RippleDirective,
  focus: focusDirective,
};

export default function setDirectives(app: App) {
  Object.keys(directives).forEach((key) => {
    app.directive(key, directives[key]);
  });
}
