export default {
  set(key: string, data: any) {
    if (typeof data === "string") {
      sessionStorage.setItem(key, data);
    } else {
      sessionStorage.setItem(key, JSON.stringify(data));
    }
  },
  get(key: string) {
    let ret = sessionStorage.getItem(key);
    try {
      // @ts-ignore
      ret = JSON.parse(ret);
    } catch (e) {
      //
    }
    return ret;
  },
  clear(key: string) {
    if (!key) {
      sessionStorage.clear();
    } else {
      sessionStorage.setItem(key, "");
    }
  },
};
