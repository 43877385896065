/* eslint-disable */
/**
 * 统一封装web端color网关调用模块
 * color网关调用说明 https://cf.jd.com/pages/viewpage.action?pageId=655715296
 */
import axios, { AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import qs from "qs";

const jdmeAppId = "ee";
const colorAppId = "JoySky";

const authTokenRef = {
  xToken: "",
  teamId: "",
};

export const getAuthTokenRef = () => {
  return authTokenRef;
};

const request = async <T>(options: AxiosRequestConfig & { functionId: string }): Promise<{ data: any }> => {
  debugger;
  const { headers: optionHeaders = {}, data = {}, method, functionId, params = {} } = options;
  const headers: AxiosRequestConfig["headers"] = {
    Accept: "*/*",
    functionId: functionId,
    loginType: "15",
    "Content-Type": "application/json",
    "x-team-id": getAuthTokenRef().teamId || "",
    "x-language": Cookies.get("focus-lang") || "zh_CN",
    "x-client": "web",
    ...optionHeaders,
  };
  const baseUrl = process.env.VUE_APP_BASE_URL;
  const config: AxiosRequestConfig = {
    headers,
    baseURL: baseUrl,
    method: method || "POST",
    withCredentials: true,
  };
  // console.log('detect ===>', isMobile);
  if (headers["Content-Type"] === "application/x-www-form-urlencoded") {
    config.data = qs.stringify({
      body: JSON.stringify({ ...data, jdmeAppId: jdmeAppId }),
      functionId: functionId,
      appid: colorAppId,
      t: new Date().getTime(),
      loginType: "15",
      uuid: Cookies.get("__jda"),
      reqSource: "mobile",
      ...params,
    });
  }
  if (headers["Content-Type"] === "application/json") {
    config.params = {
      functionId: functionId,
      appid: colorAppId,
      t: new Date().getTime(),
      loginType: "15",
      uuid: Cookies.get("__jda"),
      ...params,
    };
    config.data = {
      ...data,
      jdmeAppId: jdmeAppId,
      reqSource: "mobile",
    };
  }
  {
    const me_token = Cookies.get("me_token");
    const focus_token = Cookies.get("focus-token") || getAuthTokenRef().xToken || "";
    if (focus_token && (!me_token || me_token !== focus_token)) {
      Cookies.set("me_token", focus_token, { domain: "jd.com" });
    }
  }

  // console.log('request config ==>', config);

  try {
    const res = await axios.request<{
      code?: string | number;
      echo?: string;
      message?: string;
      data?: any;
      content?: any;
      result?: any;
      errorCode?: any;
      errorMsg?: string;
    }>(config);
    // console.log('request ===>', res);
    const { result, data, code, content, errorCode, errorMsg } = res?.data || {};
    debugger;
    // console.log('response ===>', res?.data);
    if (res?.status === 200) {
      if (errorCode === "0002") {
        // 无效的token 需要重新刷新token
        // EventBus.emit('refresh-app-token', {});
        return { data: null };
      }
      if (typeof result !== "undefined") {
        return { data: result };
      }
      if (typeof data !== "undefined") {
        return { data: data };
      }
      if (typeof content !== "undefined") {
        return { data: content };
      }
      return { data: null };
    } else {
      console.error("request error =>", errorMsg || "网络异常");
      return { data: null };
    }
  } catch (e: any) {
    console.error(e);
    return { data: null };
  }
};

export { request };

// /**
//  * 下面是调用示例
//  */

// interface IRequestResult<T> {
//   errorCode: string;
//   errorMessage: string;
//   content: T;
// }

// function demo() {
//   const gateway = new GatewayColor({
//     host: 'http://test-api.m.jd.com',
//     loginType: '15',
//     appId: 'JoySky',
//     ddAppId: 'ee',
//   });

//   /**
//    * 由于对应的后台服务不同，返回结果也不同,例如，意见征集后台会返回 IRequestResult形式数据，
//    * 但是其他后台可能会将数据放在data中而不是conent中，因此需要二次封装一下，保证统一的[data, err]的形式
//    * @param options
//    * @returns
//    */
//   async function colorFetch<T>(
//     options: AxiosRequestConfig & { functionId: string }
//   ): Promise<[T, null] | [null, RequestError]> {
//     // loginType = 15的类型需要me_token，因此每次请求都要核验一遍，其他类型不需要如此
//     {
//       const me_token = Cookies.get('me_token');
//       const focus_token = Cookies.get('focus-token');
//       if (!focus_token) {
//         return [null, new RequestError('0002', 'no focus token')];
//       }
//       if (!me_token || me_token !== focus_token) {
//         Cookies.set('me_token', focus_token, { domain: 'jd.com' });
//       }
//     }
//     const [rs, err] = await gateway.fetch<IRequestResult<T>>(options);
//     if (err) {
//       return [null, err];
//     }
//     if (!rs) {
//       return [null, new RequestError('-1', 'no result')];
//     }
//     return [rs.content, null] as [T, null];
//   }

//   /**
//    * 意见征集 复制
//    * @param bizId
//    * @returns
//    */
//   function poinionCopy(bizId: string) {
//     return colorFetch<{ opinionId: string }>({
//       functionId: 'user.opinion.copy',
//       data: { opinionId: bizId },
//       method: 'POST',
//     });
//   }

//   async function doTest() {
//     // 此时，rs的类型可以被正确的提示
//     const [rs, err] = await poinionCopy('');
//   }
// }
