import axios from "axios";
import { Notify, Toast } from "vant";
import PromiseLike from "./PromiseLike";
import type { AxiosRequestConfig, AxiosResponse, Method } from "axios";
import { getIdentity, clearCookieTip } from "@/utils";

type ParamsConfig = Record<string, any>;
type DataConfig = any;
type HeadersConfig = Record<string, string>;
type BeforeFn = () => Function;
type Interceptor<T> = (value: T) => T | Promise<T>;
type InitFnArg<T> = (() => T) | T;
export interface Identity {
  third_name: string;
  third_safetyToken: string;
  third_timestamp: string;
  dev_name?: string;
}

const CANCEL_MSG = "Operation canceled";
let mockDelayFetchData: any;
if (process.env.NODE_ENV === "development") {
  mockDelayFetchData = function mockDelayFetchData(data: any, time = 2) {
    return new Promise((res) => {
      setTimeout(() => {
        res(data);
      }, time * 1000);
    });
  };
}
let identity: Identity;

export default class FetchRequest {
  private _url!: string;
  private _params: ParamsConfig = {};
  private _headers: Record<string, string> = {};
  private _data!: Record<string, any>;
  private _defaults: AxiosRequestConfig = {};
  private _config: AxiosRequestConfig = {};

  private _unsubs: Function[] = [];
  private _silent: Boolean = false;

  private _reqInterceptors: [Interceptor<AxiosRequestConfig>, undefined][] = [];
  private _resInterceptors: [Interceptor<AxiosResponse>, undefined][] = [];

  private _notificationConfig: Record<string, any> = {};
  private _errorHandler: Function = () => false;

  private _isMock: Boolean = false;

  private _isAutoHandleResponse: Boolean = true;

  constructor(config: AxiosRequestConfig) {
    this._defaults = config || {};
    const methods: Method[] = [
      "delete",
      "get",
      "head",
      "options",
      "post",
      "put",
      "patch",
      "GET",
      "DELETE",
      "HEAD",
      "OPTIONS",
      "POST",
      "PUT",
      "PATCH",
      "link",
      "LINK",
      "unlink",
      "UNLINK",
    ];
    methods.forEach((method) => {
      (this as any)[method] = () => this.send(method);
    });
  }

  handleServerError(response: any) {
    if (this._silent) {
      return response;
    }
    const message = response.message || "请求失败，请联系管理员解决!";
    Notify({
      type: "warning",
      message: message,
      ...this._notificationConfig,
    });
    return response;
  }

  private handleError(error: Error, config: any) {
    // if (this._silent) {
    //   return error;
    // }
    if (axios.isCancel(error)) {
      console.log(`[fetchApi]: ${config.url} cancelled`);
      return;
    }
    /* if (this.handleRedirection(error as AxiosError)) {
      return;
    } */

    const msg = this._errorHandler(error);

    // 异常上报
    try {
      const data = {
        type: 1,
        code: "oa-api-error",
        msg: JSON.stringify({ ...config, responseError: error.message }),
      };
      // @ts-ignore
      window.__sgm__.custom(data);
    } catch (e) {
      //
    }
    if (window.log) {
      window.log(
        "oa_fetch_m",
        "fetch_error_400",
        sessionStorage.getItem("jmeCookie") || "",
        error.message
      );
    }
    if (error.message.indexOf("400") > -1) {
      clearCookieTip();
    } else {
      Notify({
        type: "warning",
        message: msg || "服务器异常，请联系管理员解决!",
        ...this._notificationConfig,
      });
    }

    return this;
  }

  mock(flag: Boolean) {
    this._isMock = flag ?? true;
    return this;
  }

  async send(method: Method = "GET") {
    if (!navigator.onLine) {
      Toast("无法连接到网络，请检查网络设置");
      return Promise.reject("无法连接到网络，请检查网络设置");
    }
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const instance = axios.create({});
    // interceptors
    const globalReqInt = (axios.interceptors.request as any).handlers;
    const globalResInt = (axios.interceptors.response as any).handlers;

    instance.interceptors.request.use(
      function (config) {
        if (!identity) {
          identity = getIdentity() as unknown as Identity;
        }
        config.params = { ...(config.params || {}), ...identity };
        return config;
      },
      function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
      }
    );
    this._reqInterceptors
      .concat(globalReqInt)
      .forEach((i) => instance.interceptors.request.use(...i));
    this._resInterceptors
      .concat(globalResInt)
      .forEach((i) => instance.interceptors.response.use(...i));

    const url = this._url || this._defaults.url;

    const config = {
      ...this._defaults,
      ...this._config,
      url,
      method: method || "get",
      headers: { ...(this._defaults.headers || {}), ...this._headers },
      params: { ...(this._defaults.params || {}), ...this._params },
      data: this._data,
      cancelToken: source.token,
      withCredentials: false,
    };

    if (!config.url) {
      console.error("[fetchApi]: request no url");
      return;
    }

    if (process.env.NODE_ENV === "development") {
      if (this._isMock) {
        const mock: any = config;
        return mockDelayFetchData({ data: mock.mockData });
      }
    }

    let resError;
    const resPromise = await new PromiseLike(cancel, instance(config))
      .catch((error: Error) => {
        resError = error;
        this.handleError(error, config);
      })
      .finally(() => {
        try {
          this._unsubs.forEach((unsub) => unsub());
        } catch {
          console.log(`[fetchApi]: ${config.url} unsub error`);
        }
      });

    if (resPromise) {
      const { data, status } = resPromise;
      if (!this._isAutoHandleResponse) {
        return Promise.resolve(resPromise);
      }
      if (status && status === 200 && data && !data.success) {
        return Promise.reject(this.handleServerError(data));
      }
      return Promise.resolve(data);
    }
    console.error(`[fetchApi]: ${config.url} failed`, resError);

    function cancel(msg: string | undefined) {
      source.cancel(msg || CANCEL_MSG);
    }
  }

  config(arg: AxiosRequestConfig) {
    this._config = arg || {};
    return this;
  }

  url(arg: InitFnArg<string>) {
    this._url = initFnArg(arg, this._url || this._defaults.url);
    return this;
  }

  headers(arg: InitFnArg<HeadersConfig>) {
    const headers = initFnArg(arg) || {};
    this._headers = { ...this._headers, ...headers };
    return this;
  }

  params(arg: InitFnArg<ParamsConfig>) {
    const params = initFnArg(arg) || {};
    this._params = { ...this._params, ...params };
    return this;
  }

  data(arg: InitFnArg<DataConfig>) {
    this._data = initFnArg(arg);
    return this;
  }

  subscribe(Fn: BeforeFn) {
    let unsub;
    try {
      unsub = Fn();
    } catch {
      return this;
    }
    this._unsubs.push(unsub);
    return this;
  }

  silent(flag: Boolean) {
    this._silent = !!flag;
    return this;
  }

  interceptRequest(interceptor: Interceptor<AxiosRequestConfig>) {
    this._reqInterceptors.push([interceptor, undefined]);
    return this;
  }

  interceptResponse(interceptor: Interceptor<AxiosResponse>) {
    this._resInterceptors.push([interceptor, undefined]);
    return this;
  }

  notificationConfig<T = any>(config: NonNullable<T>) {
    this._notificationConfig = config || {};
    return this;
  }

  errorHandler(handler: Function) {
    this._errorHandler = handler;
    return this;
  }

  noHandleResponse() {
    this._isAutoHandleResponse = false;
    return this;
  }
}

function initFnArg<T>(arg: InitFnArg<T>, data?: any): T {
  if (typeof arg !== "function") {
    return arg;
  }
  return (arg as Function)(data); // FIXME： 有问题
}
