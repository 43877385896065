
import { defineComponent, ref, watch, watchEffect } from "vue";
import { useRoute } from "vue-router";
import { Dialog } from "vant";
import { isUpgrade, eventBus } from "@/utils";
import { contextInject } from "@/config/context";
import { getAuthTokenRef, request } from "./utils/color";

export default defineComponent({
  setup() {
    const route = useRoute();
    const referrer = ref<string[]>([]);
    const grayRef = ref<boolean>();
    const { loading, updateLoading } = contextInject();

    try {
      const { appVersion } = window.jme.applet.getAppInfo();
      if (!isUpgrade(appVersion.replace("版本", ""))) {
        Dialog.alert({
          message: `当前版本过低，部分功能不可用，请升级系统到6.17.0以上的版本`,
        }).then(() => {
          // on close
        });
      }
    } catch {
      //
    }

    document.addEventListener("visibilitychange", function () {
      if (!document.hidden) {
        // 处理外置表单审批回调
        if (route.path === "/approve") {
          eventBus.emit("judgeApprove");
        }
        if (route.path === "/apply") {
          eventBus.emit("judgeApply");
        }
      }
    });

    const authToken = async (code: string | null) => {
      if (!code) {
        return null;
      }
      const res = await request({
        functionId: "jdme.mix.common.getToken",
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: {
          appKey: process.env.VUE_APP_APPCODE,
          authorizationCode: code,
        },
      });
      return res;
    };

    const checkGray = async () => {
      const res = await request({
        functionId: "joysky.gray.checkGrayResource",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: {},
      });
      return res;
    };

    const getAuthCode = (): Promise<string | null> => {
      if (!window.jme) {
        return Promise.resolve(null);
      }
      const jmeAppKey = process.env.VUE_APP_APPCODE;
      console.log("REACT_APP_APP_KEY =>", jmeAppKey);
      return new Promise((resolve) => {
        window.jme?.login?.getAuthorizationCode({
          appKey: jmeAppKey,
          callback: (res) => {
            console.log("getAuthorizationCode =====>", res);
            if (res?.statusCode?.toString() === "0") {
              resolve(res.code);
            } else {
              resolve("");
            }
          },
        });
      });
    };

    const getRedirectUrl = (routeName: any) => {
      if (routeName === "Home") {
        return process.env.VUE_APP_NEW_HOME_PAGE;
      } else if (routeName === "Approve") {
        return process.env.VUE_APP_NEW_APPROVE_PAGE;
      } else if (routeName === "Apply") {
        return process.env.VUE_APP_NEW_APPLIED_PAGE;
      }
      return "";
    };

    watchEffect(async () => {
      const callCheckGray = async () => {
        if (route.meta.gray) {
          updateLoading(true);
          const code = await getAuthCode();
          console.log("getAuthCode ===> ", code);
          if (!code) {
            updateLoading(false);
            return;
          }
          let authRes = await authToken(code);
          console.log("authToken ===> 11", authRes);
          if (!authRes) {
            updateLoading(false);
            return;
          } else {
            const tokenRef = getAuthTokenRef();
            tokenRef.xToken = authRes.data.token;
            tokenRef.teamId = authRes.data.teamId;
          }
          const checkRes = await checkGray();
          console.log("fetchApi ===> 22", checkRes);
          if (typeof checkRes?.data === "boolean") {
            grayRef.value = checkRes?.data;
          }
          if (checkRes?.data) {
            const url = getRedirectUrl(route.name);
            console.log("在灰度中===> ", 111, route.name, url);
            if (url) {
              location.replace(url);
              return;
            }
            updateLoading(false);
          } else {
            console.log("不在灰度中===> ", 222);
            updateLoading(false);
          }
        } else {
          updateLoading(false);
        }
      };
      try {
        console.log("grayRef ==>", grayRef.value);
        // 已经检查过是否在灰度中
        if (typeof grayRef.value === "boolean") {
          console.log("已经检查过是否在灰度中 =>", 333);
          return;
        }
        if (process.env.NODE_ENV === "production") {
          await callCheckGray();
        }
      } catch (e) {
        console.error(e);
        updateLoading(false);
      }
      // callCheckGray();
    });

    watch(
      () => route.path,
      () => {
        if (!referrer.value.includes(route.path)) {
          referrer.value.push(route.path);
        }
      },
    );

    const goBack = () => {
      if (referrer.value.length < 2 || route.path === "/home") {
        window.jme.browser.closeWeb();
      } else {
        const index = referrer.value.indexOf(route.path);
        if (index > -1) {
          referrer.value.splice(index, 1);
        }
        history.back();
      }
    };

    eventBus.on("goBack", goBack);
    return { loading };
  },
});
