import { createRouter, createWebHistory } from "vue-router";
import { eventBus } from "@/utils";

const proEnv = process.env.NODE_ENV === "production";

// createRouter 创建路由实例
const router = createRouter({
  history: createWebHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/home",
      name: "Home",
      component: () => import("@/views/Home.vue"),
      meta: { title: "流程中心", keepAlive: true, gray: true },
    },
    {
      path: "/approve",
      name: "Approve",
      component: () => import("@/views/myApprove/Index.vue"),
      meta: { title: "我的审批", keepAlive: true, gray: true },
    },
    {
      path: "/apply",
      name: "Apply",
      component: () => import("@/views/myApply/index.vue"),
      meta: { title: "我的申请", keepAlive: true, gray: true },
    },
    {
      path: "/history",
      name: "History",
      component: () => import("@/views/ApproveHistory/Index.vue"),
      meta: {
        title: "审批历史",
        keepAlive: true, //设置页面是否需要使用缓存
      },
    },
    {
      path: "/detail",
      name: "Detail",
      component: () => import("@/views/detail/Index.vue"),
      meta: { title: "申请信息" },
    },
    {
      path: "/processList",
      name: "ProcessList",
      component: () => import("@/views/processList/index.vue"),
      meta: { title: "流程申请", searchable: true },
    },
    {
      path: "/approveDetail",
      name: "approveDetail",
      component: () => import("@/views/ApproveDetail"),
      meta: { title: proEnv ? "审批详情" : "审批详情（H5）" },
    },
    {
      path: "/applyDetail",
      name: "applyDetail",
      component: () => import("@/views/ApplyDetail/index.vue"),
      meta: { title: "申请信息" },
    },
    {
      path: "/formInfoDetail",
      name: "formInfoDetail",
      component: () => import("@/views/detail/FormInfoDetail.vue"),
      meta: { title: "业务明细" },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

// @ts-ignore
router.beforeEach((to, from, next) => {
  // ...
  // 返回 false 以取消导航
  if (to.meta.title) {
    document.title = to.meta.title as string;
  }
  if (to.path === "/history" && from.path !== "/detail") {
    eventBus.emit("resetHistoryList");
  }
  if (to.path === "/approve" && from.path !== "/approveDetail") {
    eventBus.emit("resetApproveList");
  }
  if (to.path === "/apply" && from.path !== "/applyDetail") {
    eventBus.emit("resetApplyList");
  }
  next();
});

// 抛出路由实例, 在 main.js 中引用
export default router;
