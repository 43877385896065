import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_loading = _resolveComponent("base-loading")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view, null, {
    default: _withCtx(({ Component }) => [
      (_openBlock(), _createBlock(_KeepAlive, null, [
        (_ctx.$route.meta.keepAlive && !_ctx.loading)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
              key: _ctx.$route.name
            }))
          : _createCommentVNode("", true)
      ], 1024)),
      (!_ctx.$route.meta.keepAlive)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            key: _ctx.$route.name
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_base_loading, { loading: _ctx.loading }, null, 8, ["loading"])
    ]),
    _: 1
  }))
}