import { createApp, h } from "vue";
import App from "./App.vue";
import router from "./router";
import { setVant } from "./config/setVant";
import { contextProvide } from "./config/context";
import components from "@/components";
import setDirectives from "@/directives/index";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
const pkg = require("../package.json");

if (process.env.VUE_APP_ENV !== "production") {
  const VConsole = require("vconsole");
  new VConsole();
}

// 引入样式
import "@/less/index.less";
import "@/assets/iconfont.js";
import "@/assets/quarkicon.js";

document.addEventListener("contextmenu", function (e) {
  e.preventDefault();
});

// 获取接口环境变量
console.log(
  "当前环境：",
  process.env.VUE_APP_ENV,
  "   ",
  "版本：",
  pkg.version
);

const app = createApp({
  setup() {
    contextProvide();
    return () => h(App);
  },
});

app.use(router);

setVant(app);

setDirectives(app);

app.use(components);

app.mount("#app");
