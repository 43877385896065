
import { defineComponent } from "vue";
export default defineComponent({
  name: "UIcon",
  props: {
    name: {
      type: String,
      default: "",
    },
  },
});
