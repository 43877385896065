import { Canceler } from 'axios';
import { withCancel } from './cache';

export default class PromiseLike<T = any> {
  cancel: Canceler;
  promise: Promise<T>;

  constructor(cancel: Canceler, promise: Promise<T>) {
    this.cancel = cancel;
    this.promise = promise;
  }

  then<TResult1 = T, TResult2 = never>(
    onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null,
    onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null
  ): PromiseLike<T> {
    const promise = this.promise.then(onfulfilled as any, onrejected as any);
    const cancelablePromise = withCancel(promise);
    return new PromiseLike<T>((cancelablePromise as any).cancel, promise);
  }

  catch(onrejected: any) {
    const promise = this.promise.catch(onrejected);
    return new PromiseLike<T>(this.cancel, promise);
  }

  finally(onfinally?: (() => void) | undefined | null): PromiseLike<T> {
    const promise = this.promise.finally(onfinally);
    return new PromiseLike<T>(this.cancel, promise);
  }
}
