import { Dialog, Toast } from "vant";
import store from "./store";

export { default as fetchApi } from "./fetchApi";
export { default as eventBus } from "./bus";
export { default as store } from "./store";
export * from "./actions";
export { default as getFileType } from "./fileType";

export const getUrlParams = () => {
  const query = window.location.search.slice(1);
  return new URLSearchParams(query);
};

export function isUpgrade(current_version: string, compare_version = "6.17.0") {
  const compare_version_array = compare_version.split(".");
  const current_version_array = current_version.split(".");
  let is_upgrade = true;
  for (let i = 0; i < compare_version_array.length; i++) {
    if (parseInt(compare_version_array[i]) < parseInt(current_version_array[i])) {
      is_upgrade = true;
      break;
    } else {
      if (parseInt(compare_version_array[i]) === parseInt(current_version_array[i])) {
        continue;
      } else {
        is_upgrade = false;
        break;
      }
    }
  }
  return is_upgrade;
}

export const userAgent = () => {
  const isIos = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  if (isIos) {
    return "ios";
  } else {
    return "android";
  }
};

export const someColors = [
  ["#f9a907", "#fbc250"],
  ["#f05e58", "#f0736e"],
  ["#5098ee", "#61a2f0"],
  ["#8171eb", "#b28eeb"],
];

export function cookieToJson(cookie: string) {
  const cookieArr = cookie.replace(/\s/g, "").split(";");
  const obj: any = {};
  cookieArr.forEach((i) => {
    const arr = i.split("=");
    if (arr[0]) {
      obj[arr[0]] = arr[1];
    }
  });
  return obj;
}

export function dateFormat(date: Date, fmt: string) {
  let ret;
  const opt: any = {
    "Y+": date.getFullYear().toString(), // 年
    "M+": (date.getMonth() + 1).toString(), // 月
    "D+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "m+": date.getMinutes().toString(), // 分
    "s+": date.getSeconds().toString(), // 秒
  };
  for (const k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0"));
    }
  }
  return fmt;
}

function doHandleMonth(month: number) {
  let m = month + "";

  if (month.toString().length == 1) {
    m = "0" + month;
  }

  return m;
}

export function getDay(day: number) {
  const today = new Date();

  const targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;

  today.setTime(targetday_milliseconds); //注意，这行是关键代码

  const tYear = today.getFullYear();

  const tMonth = doHandleMonth(today.getMonth() + 1);

  const tDate = doHandleMonth(today.getDate());

  return tYear + "-" + tMonth + "-" + tDate;
}

export function getIdentity() {
  const third_name = store.get("third_name");
  const third_safetyToken = store.get("third_safetyToken");
  const third_timestamp = store.get("third_timestamp");
  let identity;
  if (third_name) {
    identity = { third_name, third_safetyToken, third_timestamp };
  } else {
    const cookieObj = cookieToJson(document.cookie);

    identity = Object.keys(cookieObj).reduce((obj, key: string) => {
      if (["third_name", "third_safetyToken", "third_timestamp"].indexOf(key) > -1) {
        store.set(key, cookieObj[key]);
        return { ...obj, [key]: cookieObj[key] };
      }
      return { ...obj };
    }, {});
  }

  if (process.env.VUE_APP_ENV === "development" || process.env.VUE_APP_ENV === "test") {
    // @ts-ignore
    identity.dev_name = identity.third_name;
  }

  return identity;
}

export function clearCookieTip() {
  Dialog.alert({
    message: `不好意思，好像缓存有点多了，请返回后尝试点击“我的-设置-通用-清空缓存”试一试吧，谢谢 |･ω･｀)`,
  }).then(() => {
    // on close
  });
}

export function openContactsCard(userId: string, appId?: string) {
  console.log("打开联系人:", userId, appId);
  if (appId === "nil") {
    Toast.fail("不存在此联系人");
  } else if (appId) {
    // 跳机器人聊天
    window.jme.im.openSingleChat({
      userId,
      appId,
    });
  } else {
    window.jme.im.openContactsCard({
      userId,
    });
  }
}

export function getDeviceSomeInfo() {
  let deviceInfo = window.jme?.device?.getDeviceInfo();
  if (!deviceInfo || !deviceInfo?.safeArea) {
    deviceInfo = {
      ...deviceInfo,
      safeArea: { top: "0", bottom: "0" },
    };
  }

  let isPad = deviceInfo.deviceModel === "iPad" || deviceInfo.deviceModel === "android-pad";
  if (deviceInfo.deviceModel === "android-phone") {
    // 安卓折叠屏适配
    const { deviceScreenSize } = deviceInfo;
    const { width, height } = deviceScreenSize;
    // console.log('deviceScreenSize ===> 是否折叠屏', width, height, width > height * 0.8);
    if (width > height * 0.8) {
      // docEl.style.fontSize = '20px';
      isPad = true;
    }
  }
  // const isPad = true;

  // window.jme.browser.setNaviBarHidden(true);
  // const safeAreaInsets = deviceInfo.safeAreaInsets || {};
  const { top, bottom } = deviceInfo.safeArea || deviceInfo.safeAreaInsets || {};
  // console.log('safeAreaInsets 11 ===>', deviceInfo.safeAreaInsets);
  // console.log('safeAreaInsets 22 ===>', deviceInfo.safeArea);
  // console.log('safeAreaInsets 33 ===>', top, bottom);
  let safeTop = Math.max(Number(top) || 0, 64);
  const systemName = deviceInfo?.systemName?.toLocaleLowerCase();
  // console.log('safeAreaInsets 33 ===>', systemName);
  if (!(systemName === "ios" && deviceInfo?.sysVersion?.slice(0, 2) === "11")) {
    safeTop -= 20;
  }
  const safeBottom = Math.max(Number(bottom || 0), 0);

  if (systemName === "ios") {
    // console.log('safeAreaInsets 44 ===>', safeTop, safeBottom);
    return { safeTop, safeBottom, isIos: true, isAndroid: false, isPad };
  }
  return {
    safeTop: 44,
    safeBottom: 0,
    isPad,
    isIos: false,
    isAndroid: systemName === "android",
  };
}
