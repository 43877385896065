const imgs = [
  "apng",
  "avif",
  "bmp",
  "gif",
  "ico",
  "jpg",
  "jpeg",
  "jfif",
  "pjpeg",
  "pjp",
  "png",
  "svg",
  // "tif",
  // "tiff",
  "webp",
];

const offices = ["doc", "docx", "ppt", "pptx", "xls", "xlsx"];

// const jdmeSDK = ["txt", "pdf"];

const link = ["html"];

export default function getFileType(path: string): {
  type: string;
  url: string;
} {
  const [baseUrl, params] = path.split("?");

  let url = baseUrl.replace(
    "http://storage.jd.local",
    "https://storage.jd.com"
  );

  const lastStr = url.substring(url.length - 8);
  if (
    lastStr.indexOf(".") === -1 &&
    !offices.some((a) => lastStr.indexOf(a) > -1)
  ) {
    url = url + ".txt";
  }

  const pointIndex = url.lastIndexOf(".");
  const suffix = url.substring(pointIndex + 1);
  if (params) {
    url = url + "?" + params;
  }
  if (
    offices.includes(suffix) &&
    (url.indexOf("https://storage.360buyimg.com") > -1 ||
      url.indexOf("https://storage.jd.com") > -1 ||
      // 零售云oss
      url.indexOf("jdcloud-oss.com") > -1)
  ) {
    return {
      type: "office",
      url,
    };
  } else if (imgs.includes(suffix)) {
    return {
      type: "img",
      url,
    };
  } else if (link.includes(suffix)) {
    return {
      type: "link",
      url,
    };
  } else {
    return {
      type: suffix,
      url,
    };
  }
}
