import type { Directive } from "vue";

const focusDirective: Directive = {
  mounted: (el) => {
    const input = el.querySelector(".van-field__control");
    console.log(input);
    input.focus();
  },
};

export default focusDirective;
